import * as React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.png";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import vimeo from "../img/social/vimeo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Esperti della grandine"
            style={{ width: "8em", height: "8em" }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/cookie-policy" className="navbar-item">
                        Cookie Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" className="navbar-item">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4" style={{margin: "auto"}}>
                <section>
                  <p className="footer-p">
                    Esperti della grandine snc, Via Bizzozzero, 27 Torino <br/>
                    Sede operativa via Giustetto, 17/A Pinerolo <br/>
                    P. IVA 12612970017  <br/>
                    Esperti della grandine, 2022
                  </p>
                </section>
              </div>
              <div className="column is-4 social" style={{margin: "auto"}}>
                <a title="facebook" href="https://facebook.com">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
